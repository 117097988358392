/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { isClipboardReadSupported } from '@alfa-client/tech-shared';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OzgcloudIconButtonPrimaryComponent } from '../ozgcloud-button/ozgcloud-icon-button-primary/ozgcloud-icon-button-primary.component';

@Component({
  selector: 'ozgcloud-paste-text-button',
  templateUrl: './ozgcloud-paste-text-button.component.html',
  styleUrls: ['./ozgcloud-paste-text-button.component.scss'],
  standalone: true,
  imports: [OzgcloudIconButtonPrimaryComponent],
})
export class OzgcloudPasteTextButtonComponent implements OnInit {
  @Input() tooltip: string;
  @Output() pasteEmitter: EventEmitter<string> = new EventEmitter<string>();

  isPasteSupported = false;

  ngOnInit(): void {
    this.isPasteSupported = isClipboardReadSupported();
  }

  async onPaste(): Promise<void> {
    this.pasteEmitter.emit(await navigator.clipboard.readText());
  }
}
