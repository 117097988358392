import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ods-dropdown-menu-text-item',
  standalone: true,
  imports: [CommonModule],
  styles: [':host {@apply flex min-h-12 items-start gap-4 px-4 py-3 text-start}'],
  template: `
    <ng-content select="[icon]" />
    <div class="w-80 whitespace-normal">
      <p class="mb-1 text-base font-medium">{{ title }}</p>
      <p class="mb-3 text-sm font-normal">{{ description }}</p>
      <ng-content select="[additionalContent]" />
    </div>
  `,
})
export class DropdownMenuTextItemComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) description!: string;
}
