import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';
import { IconVariants, iconVariants } from '../iconVariants';

@Component({
  selector: 'ods-discard-vorgang-icon',
  standalone: true,
  imports: [CommonModule],
  template: `<svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-primary', class)]"
    aria-hidden="true"
  >
    <path
      d="M12.75 19.4L14.35 21L16.75 18.6286L19.15 21L20.75 19.4L18.3786 17L20.75 14.6L19.15 13L16.75 15.4L14.35 13L12.75 14.6L15.15 17L12.75 19.4Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 11V3.77384H12.0294L7.625 7.93078L7.625 18.408H10.75V20.1818H7.625C6.58947 20.1818 5.75 19.3876 5.75 18.408V7.93078C5.75 7.4596 5.94815 7.00779 6.30074 6.67501L10.7051 2.51807C11.0566 2.18631 11.5329 2 12.0294 2H17C18.0355 2 18.875 2.79417 18.875 3.77384V11H17Z"
    />
  </svg>`,
})
export class DiscardVorgangIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = '';

  readonly iconVariants = iconVariants;
  readonly twMerge = twMerge;
}
