import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';
import { iconVariants, IconVariants } from '../iconVariants';

@Component({
  selector: 'ods-undo-icon',
  standalone: true,
  imports: [CommonModule],
  template: `<svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-primary', class)]"
    aria-hidden="true"
  >
    <path
      d="M13.25 8C10.6 8 8.2 8.99 6.35 10.6L2.75 7V16H11.75L8.13 12.38C9.52 11.22 11.29 10.5 13.25 10.5C16.79 10.5 19.8 12.81 20.85 16L23.22 15.22C21.83 11.03 17.9 8 13.25 8Z"
    />
  </svg>`,
})
export class UndoIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = '';

  readonly iconVariants = iconVariants;
  readonly twMerge = twMerge;
}
