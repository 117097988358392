/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ConvertForDataTestPipe } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'ods-nav-item',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, ConvertForDataTestPipe],
  template: `<a
    [routerLink]="path"
    routerLinkActive="bg-selected-light border-selected"
    class="flex min-h-8 items-center gap-2 rounded-2xl border border-transparent px-4 py-2 outline-2 outline-offset-2 outline-focus hover:border-primary focus-visible:border-background-200 focus-visible:outline"
    [attr.data-test-id]="'link-path-' + path | convertForDataTest"
  >
    <ng-content select="[icon]" />
    <p class="text-left text-sm text-text" [attr.data-test-id]="'caption-' + caption | convertForDataTest">{{ caption }}</p>
  </a>`,
})
export class NavItemComponent {
  @Input({ required: true }) caption!: string;
  @Input() path: string;

  @HostBinding('attr.role') role: string = 'menuitem';
}
