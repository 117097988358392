/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { isRevokeable } from '@alfa-client/command-shared';
import { Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { isUndefined } from 'lodash-es';
import { SnackbarCloseButtonComponent } from '../snackbar-close-button/snackbar-close-button.component';
import { SnackBarData } from '../snackbar.model';

@Component({
  selector: 'ozgcloud-snackbar-info',
  templateUrl: './snackbar-info.component.html',
  styleUrls: ['./snackbar-info.component.scss'],
  standalone: true,
  imports: [MatButton, SnackbarCloseButtonComponent],
})
export class SnackbarInfoComponent {
  public snackBarRef = inject(MatSnackBarRef<SnackbarInfoComponent>);
  public data = inject<SnackBarData>(MAT_SNACK_BAR_DATA);

  get message(): string {
    return this.data.message;
  }

  showRevokeButton(): boolean {
    if (isUndefined(this.data.commandResource)) {
      return false;
    } else {
      return isRevokeable(this.data.commandResource);
    }
  }

  revoke(): void {
    this.snackBarRef.dismissWithAction();
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
