/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { getEmbeddedResource, getUrl, hasLink, Resource, ResourceUri } from '@ngxp/rest';
import { isEqual, isNil, isNull } from 'lodash-es';
import { HttpError } from '../tech.model';
import { encodeUrlForEmbedding, isNotNull } from '../tech.util';

export interface ListResource extends Resource {
  _embedded;
}

export interface StateResource<T> {
  resource: T;
  reload: boolean;
  loading: boolean;
  loaded: boolean;
  error?: HttpError;
}

export function createEmptyStateResource<T>(loading: boolean = false): StateResource<T> {
  return createStateResource(null, loading);
}

export function createLoadingStateResource<T>(): StateResource<T> {
  return createEmptyStateResource(true);
}

export function createStateResource<T>(resource: T, loading: boolean = false): StateResource<T> {
  return { loading, loaded: !isNil(resource), reload: false, resource };
}

export function createErrorStateResource<T>(error: HttpError): StateResource<any> {
  return { ...createEmptyStateResource<T>(), error, loaded: true };
}

export function doIfLoadingRequired(stateResource: StateResource<any>, runable: () => void): boolean {
  if (isLoadingRequired(stateResource)) {
    runable();
    return true;
  }
  return false;
}

export function isLoadingRequired(stateResource: StateResource<any>): boolean {
  return !stateResource.loading && (!stateResource.loaded || stateResource.reload);
}

export function isLoaded<T>(stateResource: StateResource<T>): boolean {
  return !stateResource.loading && !stateResource.reload && isNotNull(stateResource.resource);
}

export function isNotLoading<T>(stateResource: StateResource<T>): boolean {
  return !stateResource.loading;
}

export function hasStateResourceError(stateResource: StateResource<any>): boolean {
  return !isNil(stateResource.error);
}

export function toResourceUri(resource: Resource, linkRel?: string): string {
  const url: ResourceUri = isNil(linkRel) ? getUrl(resource) : getUrl(resource, linkRel);
  return encodeUrlForEmbedding(url);
}

export function doOnValidStateResource(stateResource: StateResource<Resource>, actionOnValid: () => void): void {
  if (isValidStateResource(stateResource)) actionOnValid();
}

export function isValidStateResource(stateResource: StateResource<unknown>): boolean {
  return stateResource.loaded && isNotNull(stateResource.resource);
}

export function getEmbeddedResources<T>(stateResource: StateResource<Resource>, linkRel: string): T[] {
  if (isNil(stateResource) || isNil(stateResource.resource)) {
    return [];
  }
  return getEmbeddedResource<T[]>(stateResource.resource, linkRel);
}

export function throwErrorOn(condition: boolean, errorMsg: string): void {
  if (condition) throw Error(errorMsg);
}

export function notHasLink<T extends Resource>(resource: T, linkRel: string): boolean {
  return !hasLink(resource, linkRel);
}

export function containsLoading(stateResources: StateResource<Resource>[]): boolean {
  return stateResources.findIndex((stateResources) => stateResources.loading) > -1;
}

export function getSuccessfullyLoaded<T extends Resource>(stateResources: StateResource<T>[]): StateResource<T>[] {
  return stateResources.filter((stateResource) => isLoaded(stateResource) && !hasStateResourceError(stateResource));
}

export function isEmptyStateResource<T extends Resource>(stateResource: StateResource<T>): boolean {
  return isEqual(stateResource, createEmptyStateResource());
}

//TODO: Logik umdrehen
export function isInvalidResourceCombination<T extends Resource, B extends Resource>(
  stateResource: StateResource<T>,
  baseResource: B,
): boolean {
  return (
    stateResource.reload ||
    (!stateResource.loading &&
      ((!stateResource.loaded && isNotNull(baseResource)) || (stateResource.loaded && isNull(baseResource))))
  );
}

export function isStateResoureStable<T>(stateResource: StateResource<T>): boolean {
  return !stateResource.loading && !stateResource.reload;
}
