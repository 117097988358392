/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ConvertForDataTestPipe } from '@alfa-client/tech-shared';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormControlEditorAbstractComponent } from 'libs/design-component/src/lib/form/formcontrol-editor.abstract.component';
import { ValidationErrorComponent } from '../../validation-error/validation-error.component';

@Component({
  selector: 'ozgcloud-textarea-editor',
  templateUrl: './textarea-editor.component.html',
  styleUrls: ['./textarea-editor.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    CdkTextareaAutosize,
    FormsModule,
    ReactiveFormsModule,
    MatError,
    ValidationErrorComponent,
    ConvertForDataTestPipe,
  ],
})
export class TextAreaEditorComponent extends FormControlEditorAbstractComponent implements AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  @Input() placeholder: string;
  @Input() label: string = '';
  @Input() appearance: 'outline' | 'fill' = 'fill';
  @Input() required: boolean = false;
  @Input() autoFocus: boolean = false;

  ngAfterViewInit() {
    this.autosize.resizeToFitContent(true);
  }
}
