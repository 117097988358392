import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';
import { IconVariants, iconVariants } from '../iconVariants';

@Component({
  selector: 'ods-delete-vorgang-finally-icon',
  standalone: true,
  imports: [CommonModule],
  template: `<svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-primary', class)]"
    aria-hidden="true"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.7366 13V3.11101H12.4405L7.74777 8.05811L7.74777 13H5.75V8.05811C5.75 7.49738 5.96112 6.95968 6.3368 6.56365L11.0296 1.61654C11.4041 1.22173 11.9115 1 12.4405 1H17.7366C18.8399 1 19.7344 1.94513 19.7344 3.11101V13H17.7366Z"
    />
    <path
      d="M7.75 20C7.75 19.4477 8.19772 19 8.75 19C9.30228 19 9.75 19.4477 9.75 20C9.75 20.5523 9.30228 21 8.75 21C8.19772 21 7.75 20.5523 7.75 20Z"
    />
    <path
      d="M7.75 20C7.75 19.4477 8.19772 19 8.75 19C9.30228 19 9.75 19.4477 9.75 20C9.75 20.5523 9.30228 21 8.75 21C8.19772 21 7.75 20.5523 7.75 20Z"
    />
    <path
      d="M11.75 21C11.75 20.4477 12.1977 20 12.75 20C13.3023 20 13.75 20.4477 13.75 21V22C13.75 22.5523 13.3023 23 12.75 23C12.1977 23 11.75 22.5523 11.75 22V21Z"
    />
    <path
      d="M11.75 21C11.75 20.4477 12.1977 20 12.75 20C13.3023 20 13.75 20.4477 13.75 21V22C13.75 22.5523 13.3023 23 12.75 23C12.1977 23 11.75 22.5523 11.75 22V21Z"
    />
    <path
      d="M11.75 13C11.75 12.4477 12.1977 12 12.75 12C13.3023 12 13.75 12.4477 13.75 13V17C13.75 17.5523 13.3023 18 12.75 18C12.1977 18 11.75 17.5523 11.75 17V13Z"
    />
    <path
      d="M11.75 13C11.75 12.4477 12.1977 12 12.75 12C13.3023 12 13.75 12.4477 13.75 13V17C13.75 17.5523 13.3023 18 12.75 18C12.1977 18 11.75 17.5523 11.75 17V13Z"
    />
    <path
      d="M7.75 15C7.75 14.4477 8.19772 14 8.75 14C9.30228 14 9.75 14.4477 9.75 15V16C9.75 16.5523 9.30228 17 8.75 17C8.19772 17 7.75 16.5523 7.75 16V15Z"
    />
    <path
      d="M7.75 15C7.75 14.4477 8.19772 14 8.75 14C9.30228 14 9.75 14.4477 9.75 15V16C9.75 16.5523 9.30228 17 8.75 17C8.19772 17 7.75 16.5523 7.75 16V15Z"
    />
    <path
      d="M15.75 20C15.75 19.4477 16.1977 19 16.75 19C17.3023 19 17.75 19.4477 17.75 20V21C17.75 21.5523 17.3023 22 16.75 22C16.1977 22 15.75 21.5523 15.75 21V20Z"
    />
    <path
      d="M15.75 20C15.75 19.4477 16.1977 19 16.75 19C17.3023 19 17.75 19.4477 17.75 20V21C17.75 21.5523 17.3023 22 16.75 22C16.1977 22 15.75 21.5523 15.75 21V20Z"
    />
    <path
      d="M15.75 15C15.75 14.4477 16.1977 14 16.75 14C17.3023 14 17.75 14.4477 17.75 15V16C17.75 16.5523 17.3023 17 16.75 17C16.1977 17 15.75 16.5523 15.75 16V15Z"
    />
    <path
      d="M15.75 15C15.75 14.4477 16.1977 14 16.75 14C17.3023 14 17.75 14.4477 17.75 15V16C17.75 16.5523 17.3023 17 16.75 17C16.1977 17 15.75 16.5523 15.75 16V15Z"
    />
  </svg> `,
})
export class DeleteVorgangFinallyIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = '';

  readonly iconVariants = iconVariants;
  readonly twMerge = twMerge;
}
