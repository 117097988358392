/*
 * Copyright (C) 2025 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';
import { iconVariants, IconVariants } from '../iconVariants';

@Component({
  selector: 'ods-forward-vorgang-icon',
  standalone: true,
  imports: [NgClass],
  template: `<svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="twMerge(iconVariants({ size }), 'fill-primary', class)"
    aria-hidden="true"
  >
    <path d="M17.7222 16.4V14L22 18.2L17.7222 22.4V19.94C14.6667 19.94 12.5278 20.9 11 23C11.6111 20 13.4444 17 17.7222 16.4Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.25 11V3.77384H11.2794L6.875 7.93078L6.875 18.408H9V20.1818H6.875C5.83947 20.1818 5 19.3876 5 18.408V7.93078C5 7.4596 5.19815 7.00779 5.55074 6.67501L9.95513 2.51807C10.3066 2.18631 10.7829 2 11.2794 2H16.25C17.2855 2 18.125 2.79417 18.125 3.77384V11H16.25Z"
    />
  </svg>`,
})
export class ForwardVorgangIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = undefined;

  readonly iconVariants = iconVariants;
  readonly twMerge = twMerge;
}
