/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgClass, NgComponentOutlet } from '@angular/common';
import { Component, inject, Injector, OnInit } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { FixedDialogData } from './fixed-dialog-data.model';

@Component({
  selector: 'ozgcloud-fixed-dialog',
  templateUrl: './fixed-dialog.component.html',
  standalone: true,
  imports: [MatDialogTitle, MatIconButton, NgClass, MatIcon, MatDialogClose, CdkScrollable, MatDialogContent, NgComponentOutlet],
})
export class FixedDialogComponent implements OnInit {
  public data = inject<FixedDialogData>(MAT_DIALOG_DATA);
  private injector = inject(Injector);

  componentInjector: Injector;

  isMinimized: boolean;

  ngOnInit(): void {
    this.componentInjector = Injector.create({
      providers: [],
      parent: this.injector,
    });
  }

  minimize() {
    this.isMinimized = !this.isMinimized;
  }
}
