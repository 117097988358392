import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';
import { iconVariants, IconVariants } from '../iconVariants';

@Component({
  selector: 'ods-update-icon',
  standalone: true,
  imports: [CommonModule],
  template: `<svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-primary', class)]"
    aria-hidden="true"
  >
    <path
      d="M21.75 10.12H14.97L17.71 7.3C14.98 4.6 10.56 4.5 7.83 7.2C5.1 9.91 5.1 14.28 7.83 16.99C10.56 19.7 14.98 19.7 17.71 16.99C19.07 15.65 19.75 14.08 19.75 12.1H21.75C21.75 14.08 20.87 16.65 19.11 18.39C15.6 21.87 9.9 21.87 6.39 18.39C2.89 14.92 2.86 9.28 6.37 5.81C9.88 2.34 15.51 2.34 19.02 5.81L21.75 3V10.12ZM13.25 8V12.25L16.75 14.33L16.03 15.54L11.75 13V8H13.25Z"
    />
  </svg>`,
})
export class UpdateIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = '';

  readonly iconVariants = iconVariants;
  readonly twMerge = twMerge;
}
