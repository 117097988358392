import { Component, Input } from '@angular/core';
import { OpenLinkIconComponent } from '../../icons/open-link-icon/open-link-icon.component';
import { LinkComponent } from '../../link/link.component';

@Component({
  selector: 'ods-dropdown-menu-link-item',
  standalone: true,
  imports: [OpenLinkIconComponent, LinkComponent],
  styles: [':host {@apply first:mt-2}'],
  template: ` <ods-link [url]="url" class="bg-whitetext" [openInNewTab]="true">
    <div class="flex items-center gap-2 px-4 py-3">
      <p class="font-medium text-primary">{{ text }}</p>
      <ods-open-link-icon class="size-5" />
      <span class="sr-only">Öffnet in einem neuen Tab</span>
    </div>
  </ods-link>`,
})
export class DropdownMenuLinkItemComponent {
  @Input() url: string;
  @Input() text: string;
}
