import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InjectorService {
  private static injector: Injector;

  constructor(injector: Injector) {
    InjectorService.injector = injector;
  }

  public static getInjector(): Injector {
    return InjectorService.injector;
  }
}
