/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ApiError, isNotUndefined } from '@alfa-client/tech-shared';
import { BasicDialogComponent, BasicDialogData, FixedDialogComponent, FixedDialogData } from '@alfa-client/ui';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { InternalServerErrorDialogComponent } from '../notification/internal-server-error-dialog/internal-server-error-dialog.component';
import { RetryInTimeDialog } from './retry-in-time.dialog';

@Injectable({ providedIn: 'root' })
export class DialogService {
  private dialog = inject(MatDialog);

  fixedSingleDialogRef: MatDialogRef<FixedDialogComponent>;

  readonly FIXED_DIALOG_CONFIG: MatDialogConfig = {
    width: '520px',
    position: { bottom: '0', right: '30px' },
    hasBackdrop: false,
    panelClass: 'fixed-dialog',
    disableClose: true,
    autoFocus: false,
    scrollStrategy: new NoopScrollStrategy(),
  };

  readonly SERVER_ERROR_DIALOG_CONFIG: MatDialogConfig = {
    width: '440px',
    disableClose: true,
    panelClass: 'notification-dialog',
  };

  readonly BASIC_DIALOG_CONFIG: MatDialogConfig = {
    width: 'auto',
    hasBackdrop: true,
    autoFocus: true,
    scrollStrategy: new NoopScrollStrategy(),
  };

  readonly WIDE_DIALOG_CONFIG: MatDialogConfig = {
    width: '610px',
    restoreFocus: false,
  };

  public openWidely<T>(component: ComponentType<T>, config?: MatDialogConfig): MatDialogRef<T> {
    return this.open<T>(component, { ...config, ...this.WIDE_DIALOG_CONFIG });
  }

  public open<T>(component: ComponentType<T>, config?: MatDialogConfig): MatDialogRef<T> {
    return this.dialog.open<T>(component, config);
  }

  public openApiErrorInfo<T>(apiError: ApiError): MatDialogRef<InternalServerErrorDialogComponent> {
    return this.dialog.open(InternalServerErrorDialogComponent, {
      ...this.SERVER_ERROR_DIALOG_CONFIG,
      data: apiError,
    });
  }

  public openFixed(data: FixedDialogData): MatDialogRef<FixedDialogComponent> {
    if (isNotUndefined(this.fixedSingleDialogRef)) {
      this.fixedSingleDialogRef.close();
    }
    this.fixedSingleDialogRef = this.dialog.open(FixedDialogComponent, {
      ...this.FIXED_DIALOG_CONFIG,
      data,
    });
    return this.fixedSingleDialogRef;
  }

  public openBasic(data: BasicDialogData): MatDialogRef<BasicDialogComponent> {
    return this.dialog.open(BasicDialogComponent, { ...this.BASIC_DIALOG_CONFIG, data });
  }

  public getRetryDialog(): RetryInTimeDialog {
    return new RetryInTimeDialog(this);
  }

  public closeAll(): void {
    this.dialog.closeAll();
  }

  public closeById(dialogId: string): void {
    this.dialog.getDialogById(dialogId).close();
  }
}
