import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';
import { iconVariants, IconVariants } from '../iconVariants';

@Component({
  selector: 'ods-undo-request-vorgang-deletion-icon',
  standalone: true,
  imports: [CommonModule],
  template: `<svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-primary', class)]"
    aria-hidden="true"
  >
    <path
      d="M11.75 16H13.75V11.85L15.35 13.4L16.75 12L12.75 8L8.75 12L10.15 13.4L11.75 11.85V16ZM7.75 21C7.2 21 6.72917 20.8042 6.3375 20.4125C5.94583 20.0208 5.75 19.55 5.75 19V6H4.75V4H9.75V3H15.75V4H20.75V6H19.75V19C19.75 19.55 19.5542 20.0208 19.1625 20.4125C18.7708 20.8042 18.3 21 17.75 21H7.75ZM17.75 6H7.75V19H17.75V6Z"
    />
  </svg>`,
})
export class UndoRequestVorgangDeletionIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = '';

  readonly iconVariants = iconVariants;
  readonly twMerge = twMerge;
}
