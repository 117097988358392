/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HasLinkPipe } from '@alfa-client/tech-shared';
import { SlideToggleComponent } from '@alfa-client/ui';
import { UserSettingsSharedModule } from '@alfa-client/user-settings-shared';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CloseIconComponent, DropdownMenuComponent, SettingsIconComponent } from '@ods/system';
import { UserSettingsContainerComponent } from './user-settings-container/user-settings-container.component';
import { UserSettingsDarkmodeContainerComponent } from './user-settings-container/user-settings/user-settings-dropdown/user-settings-darkmode-container/user-settings-darkmode-container.component';
import { UserSettingsDarkmodeComponent } from './user-settings-container/user-settings/user-settings-dropdown/user-settings-darkmode-container/user-settings-darkmode/user-settings-darkmode.component';
import { UserSettingsDropdownComponent } from './user-settings-container/user-settings/user-settings-dropdown/user-settings-dropdown.component';
import { UserSettingsEmailBenachrichtigungContainerComponent } from './user-settings-container/user-settings/user-settings-dropdown/user-settings-email-benachrichtigung-container/user-settings-email-benachrichtigung-container.component';
import { UserSettingsEmailBenachrichtigungComponent } from './user-settings-container/user-settings/user-settings-dropdown/user-settings-email-benachrichtigung-container/user-settings-email-benachrichtigung/user-settings-email-benachrichtigung.component';
import { UserSettingsComponent } from './user-settings-container/user-settings/user-settings.component';

@NgModule({
  imports: [
    CommonModule,
    UserSettingsSharedModule,
    DropdownMenuComponent,
    CloseIconComponent,
    SettingsIconComponent,
    SlideToggleComponent,
    HasLinkPipe,
  ],
  declarations: [
    UserSettingsContainerComponent,
    UserSettingsEmailBenachrichtigungComponent,
    UserSettingsDarkmodeComponent,
    UserSettingsComponent,
    UserSettingsDarkmodeContainerComponent,
    UserSettingsEmailBenachrichtigungContainerComponent,
    UserSettingsDropdownComponent,
  ],
  exports: [
    UserSettingsContainerComponent,
    UserSettingsEmailBenachrichtigungComponent,
    UserSettingsDarkmodeContainerComponent,
    UserSettingsEmailBenachrichtigungContainerComponent,
  ],
})
export class UserSettingsModule {}
