/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'ods-search-result-item',
  standalone: true,
  imports: [CommonModule],
  template: ` <button
    *ngIf="title"
    [ngClass]="[
      'flex w-full justify-between border-2 border-transparent px-6 py-3',
      'hover:border-focus focus:border-focus focus:outline-none',
    ]"
    role="listitem"
    tabindex="-1"
    (click)="itemClicked.emit()"
    data-test-id="item-button"
    #button
  >
    <div class="flex flex-col items-start justify-between text-start text-text">
      <p class="text-base font-medium" data-test-id="item-button-title">{{ title }}</p>
      <p class="text-sm" data-test-id="item-button-description">{{ description }}</p>
    </div>
    <ng-content select="[action-button]" />
  </button>`,
})
export class SearchResultItemComponent {
  @Input({ required: true }) title!: string;
  @Input() description: string = '';

  @Output() public itemClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @ViewChild('button') buttonRef: ElementRef;

  public setFocus() {
    this.buttonRef.nativeElement.focus();
  }
}
