/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ApiRootLinkRel, ApiRootResource } from '@alfa-client/api-root-shared';
import { StateResource } from '@alfa-client/tech-shared';
import { Component, Input } from '@angular/core';
import { hasLink } from '@ngxp/rest';
import { isNil } from 'lodash-es';

@Component({
  selector: 'alfa-help-menu',
  templateUrl: './help-menu.component.html',
})
export class HelpMenuComponent {
  @Input() set apiRootStateResource(value: StateResource<ApiRootResource>) {
    this._apiRootStateResource = value;
    this.enabled = this.hasMenuItems();
  }

  get apiRootStateResource(): StateResource<ApiRootResource> {
    return this._apiRootStateResource;
  }

  private _apiRootStateResource: StateResource<ApiRootResource>;

  public enabled: boolean = false;

  public readonly apiRootLinkRel = ApiRootLinkRel;

  public hasMenuItems(): boolean {
    return this.hasDocumentationLink() || this.hasImpressumUrl();
  }

  private hasImpressumUrl(): boolean {
    return !isNil(this.apiRootStateResource?.resource?.impressumUrl);
  }

  private hasDocumentationLink(): boolean {
    return hasLink(this.apiRootStateResource?.resource, ApiRootLinkRel.DOCUMENTATIONS);
  }
}
