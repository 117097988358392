/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommandResource, hasCommandError } from '@alfa-client/command-shared';
import { StateResource, createEmptyStateResource, isLoaded } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent, ErrorMessageComponent, buttonVariants } from '@ods/system';
import { VariantProps } from 'class-variance-authority';
import { isNil } from 'lodash-es';

type ButtonVariants = VariantProps<typeof buttonVariants>;

@Component({
  selector: 'ods-button-with-spinner',
  standalone: true,
  imports: [CommonModule, ButtonComponent, ErrorMessageComponent],
  styles: [':host {@apply flex flex-col w-fit}'],
  template: ` <ods-error-message *ngIf="isError" text="Ein Fehler ist aufgetreten." subText="Versuchen Sie es noch einmal.">
    </ods-error-message>
    <ods-button
      [text]="text"
      [variant]="variant"
      [size]="size"
      [dataTestId]="dataTestId"
      [isLoading]="isLoading"
      (click)="clickEmitter.emit()"
    >
      <ng-content icon select="[icon]" />
    </ods-button>`,
})
export class ButtonWithSpinnerComponent {
  @Input() set stateResource(value: StateResource<unknown>) {
    if (!isNil(value)) this._stateResource = value;
  }
  @Input() text: string = '';
  @Input() dataTestId: string = '';
  @Input() variant: ButtonVariants['variant'] = 'primary';
  @Input() size: ButtonVariants['size'] = 'medium';

  @Output() public clickEmitter: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  _stateResource: StateResource<unknown> = createEmptyStateResource();

  get isLoading(): boolean {
    return this._stateResource.loading || this._stateResource.reload;
  }

  get isError(): boolean {
    return isLoaded(this._stateResource) && hasCommandError(<CommandResource>this._stateResource.resource);
  }
}
