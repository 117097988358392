import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';
import { iconVariants, IconVariants } from '../iconVariants';

@Component({
  selector: 'ods-account-circle-icon',
  standalone: true,
  imports: [CommonModule],
  template: `<svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-primary', class)]"
    aria-hidden="true"
  >
    <path
      d="M12.75 2C7.23 2 2.75 6.48 2.75 12C2.75 17.52 7.23 22 12.75 22C18.27 22 22.75 17.52 22.75 12C22.75 6.48 18.27 2 12.75 2ZM7.82 18.28C8.25 17.38 10.87 16.5 12.75 16.5C14.63 16.5 17.26 17.38 17.68 18.28C16.32 19.36 14.61 20 12.75 20C10.89 20 9.18 19.36 7.82 18.28ZM19.11 16.83C17.68 15.09 14.21 14.5 12.75 14.5C11.29 14.5 7.82 15.09 6.39 16.83C5.37 15.49 4.75 13.82 4.75 12C4.75 7.59 8.34 4 12.75 4C17.16 4 20.75 7.59 20.75 12C20.75 13.82 20.13 15.49 19.11 16.83ZM12.75 6C10.81 6 9.25 7.56 9.25 9.5C9.25 11.44 10.81 13 12.75 13C14.69 13 16.25 11.44 16.25 9.5C16.25 7.56 14.69 6 12.75 6ZM12.75 11C11.92 11 11.25 10.33 11.25 9.5C11.25 8.67 11.92 8 12.75 8C13.58 8 14.25 8.67 14.25 9.5C14.25 10.33 13.58 11 12.75 11Z"
    />
  </svg>`,
})
export class AccountCircleIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = '';

  readonly iconVariants = iconVariants;
  readonly twMerge = twMerge;
}
