/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { inject, Injectable } from '@angular/core';
import { getUrl, Resource, ResourceFactory, ResourceUri } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { CreateResourceData, LinkRelationName, SaveResourceData } from './resource.model';
import { ListResource } from './resource.util';

@Injectable({ providedIn: 'root' })
export class ResourceRepository<T extends Resource = Resource> {
  private resourceFactory = inject(ResourceFactory);

  static SEARCH_PARAM: string = 'searchBy';

  public getListResource(resource: Resource, linkRel: string): Observable<ListResource> {
    const uriWithoutParameter: string = this.getUrlWithoutParameter(getUrl(resource, linkRel));
    return this.resourceFactory.fromId(uriWithoutParameter).get();
  }

  getUrlWithoutParameter(uri: ResourceUri): string {
    if (uri.includes('{')) {
      return uri.substring(0, uri.indexOf('{'));
    }
    return uri;
  }

  public createResource(createResourceData: CreateResourceData<Resource>): Observable<T> {
    return this.resourceFactory.from(createResourceData.resource).post(createResourceData.linkRel, createResourceData.toCreate);
  }

  public getResource<T>(uri: ResourceUri): Observable<T> {
    return this.resourceFactory.fromId(uri).get();
  }

  public save(saveResourceData: SaveResourceData<Resource>): Observable<Resource> {
    return this.resourceFactory.from(saveResourceData.resource).put(saveResourceData.linkRel, saveResourceData.toSave);
  }

  public patch(patchResourceData: SaveResourceData<Resource>): Observable<Resource> {
    return this.resourceFactory.from(patchResourceData.resource).patch(patchResourceData.linkRel, patchResourceData.toSave);
  }

  public delete(resource: Resource, linkRel: LinkRelationName): Observable<Resource> {
    return this.resourceFactory.from(resource).delete(linkRel);
  }

  public search<T>(resource: Resource, linkRel: LinkRelationName, searchBy: string): Observable<T> {
    return this.resourceFactory.fromId(this.buildSearchUri(new URL(getUrl(resource, linkRel)), searchBy)).get();
  }

  buildSearchUri(url: URL, searchBy: string): ResourceUri {
    url.searchParams.set(ResourceRepository.SEARCH_PARAM, searchBy);
    return url.href;
  }
}
