/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { StateResource, createEmptyStateResource } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconVariants, SpinnerIconComponent } from '@ods/system';
import { isNil } from 'lodash-es';

@Component({
  selector: 'ods-spinner',
  standalone: true,
  imports: [CommonModule, SpinnerIconComponent],
  template: `@if (isLoading) {
      <ods-spinner-icon [size]="spinnerSize" />
    } @else {
      <ng-content />
    }`,
})
export class SpinnerComponent {
  @Input() dataTestId: string = '';
  @Input() stateResource: StateResource<unknown> = createEmptyStateResource();
  @Input() spinnerSize: IconVariants['size'] = 'extra-large';

  @Output() public clickEmitter: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  public get isLoading(): boolean {
    return !isNil(this.stateResource) && (this.stateResource.loading || this.stateResource.reload);
  }
}
