<!--

    Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
@if (vorgang | hasLink: linkRel.ASSIGN) {
  <ods-button-with-spinner
    svgIcon="account_outline"
    [tooltip]="'Bearbeiter zuordnen'"
    tooltipAriaType="aria-labelledby"
    data-test-id="assign-user-profile-icon-button"
    variant="ghost"
    size="fit"
    (clickEmitter)="showUserProfileSearch()"
  >
    <ods-account-circle-icon icon class="fill-text" />
  </ods-button-with-spinner>
}
