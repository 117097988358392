/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ButtonToggleComponent } from '@ods/system';
import { FormControlEditorAbstractComponent } from '../formcontrol-editor.abstract.component';

@Component({
  selector: 'ods-button-toggle-group',
  standalone: true,
  imports: [CommonModule],
  template: `<div
    class="flex divide-x divide-neutral-300 overflow-hidden rounded-full border border-neutral-300 bg-white dark:divide-neutral-600 dark:border-neutral-600 dark:bg-neutral-700"
    role="group"
  >
    <ng-content></ng-content>
  </div>`,
})
export class ButtonToggleGroupComponent extends FormControlEditorAbstractComponent implements AfterContentInit {
  @ContentChildren(ButtonToggleComponent) buttonToggles!: QueryList<ButtonToggleComponent>;

  ngAfterContentInit(): void {
    this.buttonToggles.forEach((toggleButton: ButtonToggleComponent) => this.passPropsToChild(toggleButton));
  }

  passPropsToChild(toggleButton: ButtonToggleComponent): void {
    toggleButton.onClick = this.setFormControlValue(toggleButton.value);
    toggleButton.isSelected = this.isValueSelected();
  }

  setFormControlValue(value: string): () => void {
    const formControl: FormControl = this.fieldControl;
    return () => {
      if (formControl.value !== value) formControl.setValue(value);
    };
  }

  isValueSelected(): (value: string) => boolean {
    const formControl: FormControl = this.fieldControl;
    return (value: string) => formControl.value === value;
  }
}
