import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonVariants, buttonVariants } from '@ods/system';

@Component({
  selector: 'ods-routing-button',
  standalone: true,
  imports: [CommonModule, RouterLink],
  host: { class: 'block' },
  template: `<a [routerLink]="'/' + linkPath" [attr.data-test-id]="dataTestId" [ngClass]="buttonVariants({ size, variant })">
    <ng-content select="[icon]" />
    @if (text) {
      <p class="flex-grow">{{ text }}</p>
    }
  </a>`,
})
export class RoutingButtonComponent {
  @Input() linkPath: string;
  @Input() text: string;
  @Input() dataTestId: string;
  @Input() variant: ButtonVariants['variant'];
  @Input() size: ButtonVariants['size'];

  readonly buttonVariants = buttonVariants;
}
