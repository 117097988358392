/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
export enum VorgangListLinkRel {
  VORGANG_HEADER_LIST = 'vorgangHeaderList',
  NEXT = 'next',
}

export enum VorgangHeaderLinkRel {
  VORGANG_WITH_EINGANG = 'vorgang-mit-eingang',
  ASSIGN = 'assign',
  WIEDERVORLAGEN = 'wiedervorlagen',
  KOMMENTARE = 'kommentare',
  ASSIGNED_TO = 'assignedTo',
  POSTFACH_MAILS = 'postfachMails',
}

export enum VorgangWithEingangLinkRel {
  ANNEHMEN = 'annehmen',
  VERWERFEN = 'verwerfen',
  ZURUECKHOLEN = 'zurueckholen',
  BEARBEITEN = 'bearbeiten',
  BESCHEIDEN = 'bescheiden',
  ZURUECKSTELLEN = 'zurueckstellen',
  ABSCHLIESSEN = 'abschliessen',
  WIEDEREROEFFNEN = 'wiedereroeffnen',
  LOESCHEN_ANFORDERN = 'loeschen_anfordern',
  LOESCH_ANFORDERUNG = 'loesch_anforderung',

  ATTACHMENTS = 'attachments',
  FORWARD = 'forward',
  FORWARD_BY_EMAIL = 'forwardByEmail',
  FORWARD_BY_OZGCLOUD = 'forwardByOzgCloud',
  PENDING_COMMANDS = 'pending-commands',
  ASSIGN = 'assign',
  REPRESENTATIONS = 'representations',
  HISTORIE = 'historie',
  SEARCH_USER_PROFILES = 'search-user-profiles',
  EXPORT = 'export',
  ARCHIVE = 'archive',
  CREATE_BESCHEID_DRAFT = 'createBescheidDraft',
  PROCESS_VORGANG = 'processVorgang',

  SET_AKTENZEICHEN = 'set_aktenzeichen',
  BESCHEID_DRAFT = 'bescheidDraft',
  BESCHEIDE = 'bescheide',
  UEBERSPRINGEN_UND_ABSCHLIESSEN = 'ueberspringen_und_abschliessen',
  DOWNLOAD_ATTACHMENTS = 'downloadAttachments',

  SEARCH_ORGANISATIONS_EINHEIT = 'searchOrganisationsEinheit',
  SEARCH_EXTERNE_FACHSTELLE = 'searchFachstelle',

  COLLABORATIONS = 'collaborations',
}

export enum LoeschAnforderungLinkRel {
  EXECUTE_LOESCHEN = 'execute_loeschen',
}
