<!--

Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
Ministerpräsidenten des Landes Schleswig-Holstein
Staatskanzlei
Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

Lizenziert unter der EUPL, Version 1.2 oder - sobald
diese von der Europäischen Kommission genehmigt wurden -
Folgeversionen der EUPL ("Lizenz");
Sie dürfen dieses Werk ausschließlich gemäß
dieser Lizenz nutzen.
Eine Kopie der Lizenz finden Sie hier:

https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

Sofern nicht durch anwendbare Rechtsvorschriften
gefordert oder in schriftlicher Form vereinbart, wird
die unter der Lizenz verbreitete Software "so wie sie
ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
ausdrücklich oder stillschweigend - verbreitet.
Die sprachspezifischen Genehmigungen und Beschränkungen
unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<mat-form-field
  [subscriptSizing]="subscriptSizing"
  class="compact-input"
  appearance="{{ appearance }}"
  [floatLabel]="readOnly || autoFocus ? 'always' : 'auto'"
>
  <mat-label>{{ label }}</mat-label>
  <div class="wrapper">
    <input
      #inputElement
      matInput
      placeholder="{{ placeholder }}"
      [formControl]="fieldControl"
      [autocomplete]="autocomplete"
      [readonly]="readOnly"
      (blur)="touch()"
      [required]="required"
      [attr.data-test-id]="(label | convertForDataTest) + '-text-input'"
      [class.with-clear-button]="showClearButton$ | async"
    />
    @if (showClearButton$ | async) {
      <button
        data-test-id="clear-button"
        class="clear-button"
        type="button"
        (click)="onClear()"
        mat-icon-button
        aria-label="Leeren"
      >
        <mat-icon>close</mat-icon>
      </button>
    }
  </div>
  <mat-error>
    <ozgcloud-validation-error
      [attr.data-test-id]="(getPlaceholderLabel() | convertForDataTest) + '-text-error'"
      [invalidParams]="invalidParams"
      [label]="getPlaceholderLabel()"
    ></ozgcloud-validation-error>
  </mat-error>
</mat-form-field>
