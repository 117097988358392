/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { AccessibilityButtonComponent } from '@alfa-client/common';
import { UserAssistanceModule } from '@alfa-client/user-assistance';
import { UserProfileModule } from '@alfa-client/user-profile';
import { UserSettingsModule } from '@alfa-client/user-settings';
import { VorgangSharedUiModule } from '@alfa-client/vorgang-shared-ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderContainerComponent } from './header-container/header-container.component';
import { HeaderLogoComponent } from './header-container/header/header-logo/header-logo.component';
import { HeaderComponent } from './header-container/header/header.component';

@NgModule({
  declarations: [HeaderComponent, HeaderContainerComponent, HeaderLogoComponent],
  imports: [
    CommonModule,
    RouterModule,
    VorgangSharedUiModule,
    UserProfileModule,
    UserSettingsModule,
    UserAssistanceModule,
    AccessibilityButtonComponent,
  ],
  exports: [HeaderContainerComponent],
})
export class NavigationModule {}
