<!--

Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
Ministerpräsidenten des Landes Schleswig-Holstein
Staatskanzlei
Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

Lizenziert unter der EUPL, Version 1.2 oder - sobald
diese von der Europäischen Kommission genehmigt wurden -
Folgeversionen der EUPL ("Lizenz");
Sie dürfen dieses Werk ausschließlich gemäß
dieser Lizenz nutzen.
Eine Kopie der Lizenz finden Sie hier:

https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

Sofern nicht durch anwendbare Rechtsvorschriften
gefordert oder in schriftlicher Form vereinbart, wird
die unter der Lizenz verbreitete Software "so wie sie
ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
ausdrücklich oder stillschweigend - verbreitet.
Die sprachspezifischen Genehmigungen und Beschränkungen
unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
@if (icon) {
  <mat-icon data-test-class="icon" [class.with-text]="text" [style.visibility]="isDisabled ? 'hidden' : 'visible'">
    {{ icon }}
  </mat-icon>
}

@if (svgIcon) {
  <mat-icon
    data-test-class="icon"
    [class.with-text]="text"
    [svgIcon]="svgIcon"
    [style.visibility]="isDisabled ? 'hidden' : 'visible'"
  >
  </mat-icon>
}

@if (text) {
  <span class="text-sm" data-test-class="button-with-spinner-text">{{ text }}</span>
}

<ozgcloud-spinner [diameter]="22" padding="0" [stateResource]="stateResource" [show]="showSpinner"> </ozgcloud-spinner>
