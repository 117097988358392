/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component } from '@angular/core';
import { MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { OzgcloudStrokedButtonWithSpinnerComponent } from '../../ozgcloud-button/ozgcloud-stroked-button-with-spinner/ozgcloud-stroked-button-with-spinner.component';

@Component({
  selector: 'ozgcloud-connection-timeout-retry-fail-dialog',
  templateUrl: './connection-timeout-retry-fail-dialog.component.html',
  styleUrls: ['./connection-timeout-retry-fail-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatIcon, CdkScrollable, MatDialogContent, OzgcloudStrokedButtonWithSpinnerComponent],
})
export class ConnectionTimeoutRetryFailDialogComponent {
  reload(): void {
    window.location.reload();
  }
}
